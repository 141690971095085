<template>
    <div class="filter-wrapper">
        <div class="search-wrapper w-100 mb-05rem pointer" :class="{ selected: isFilterSearchFoucs }" @click="toggleBasketballFavModal($event)">       
            <img class="search-icon" src="../../../../static/images/icons/icon_search.png"/>
            <div class="search-placeholder">{{ $t('SEARCH') }}</div>
        </div>
        <!-- <div class="search-btn__fav" @click="toggleGlobalSearchModal($event)">
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1693 5.56612H6.16927V9.56612H4.83594V5.56612H0.835938V4.23279H4.83594V0.232788H6.16927V4.23279H10.1693V5.56612Z" fill="black"/>
            </svg>
            <span class="ml-05rem">{{ $t('FAVOURITE') }}</span>
        </div> -->

        <!-- favourite league list -->
        <!-- <div v-if="isLogin" class="mt-m">
            <div class="filter-fav__card" v-for="(level1, index) in favCategory" :key="index">
                <div class="filter-fav__card-title filter-fav__card-category mb-1rem" @click="handleFavCategoryExpand(level1)" :class="{ 'pe-none' : level1.list.length === 0 }">
                    <div class="filter-fav__name-wrapper">
                        <div class="filter-fav__icon">
                            <img src="../../../../static/images/icons/icon_star_white_solid_small.png">
                        </div>
                        <span class="filter-fav__card-text" :title="level1.title">{{ $t(level1.title) }}</span>
                    </div>
                    <div class="filter-fav__right">
                         <i class="filter-fav__arrow" :class="{'selected' : level1.isExpand}">
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="#6F8C9B"/>                           
                            </svg>
                        </i>
                    </div>
                </div>
                <div class="filter-fav__child-wrapper" v-show="level1.isExpand">              
                    <router-link class="filter-fav__child-container" :to="urlPath(level1.title, league)"  v-for="(league, index) in level1.list" :key="index">
                        <div class="filter-fav__card-title mb-1rem">
                            <div class="filter-fav__name-wrapper">
                            <Icon
                                :logoUrl="league.logo"
                                :category="'country'"
                                class="filter-fav__icon"
                            ></Icon>                                
                            <span class="filter-fav__name filter-fav__name-child">{{ league.name }}</span>
                            </div>
                            <div class="filter-fav__right">
                                <div class="btn-fav-wrapper" @click="handleFavCategoryToggle(league, level1.title, $event)">
                                    <div class="btn-fav" :class="favMatchClassObj(league)"></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div> -->

        <!-- popular league list -->
        <div v-for="(level1, index) in leagueList" :key="index">
            <p class="filter-fav__title">{{ $t(level1.categoryDisplayName) }}</p>
            <div class="filter-fav__card" v-for="(level2, index) in level1.countries" :key="index">
                <router-link class="filter-fav__card-title__wrapper" :to="urlPath('POPULAR', level2)">
                    <div class="filter-fav__card-title"  @click="handleFilterChildExpand(level2, $event)">
                        <div class="filter-fav__name-wrapper">
                            <Icon
                                :logoUrl="level2.logo"
                                :category="'country'"
                                class="filter-fav__icon"
                            ></Icon>
                            <span class="filter-fav__name" :title="level2.countryDisplayName">{{ level2.countryDisplayName }}</span>
                        </div>
                        <div class="filter-fav__right">
                            <div class="btn-fav-wrapper" v-if="level2.rootFlag" @click="handleFavPopularToggle(level1, level2, $event)">
                                <div class="btn-fav" :class="favMatchClassObj(level2)" ></div>
                            </div>
                            <div v-else>   
                                <i class="filter-fav__arrow" :class="{'selected' : level2.isShowChild}">
                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="#6F8C9B"/>                           
                                    </svg>
                                </i>
                            </div>
                        </div>
                    </div>
                </router-link>
                <div v-if="!level2.rootFlag" v-show="level2.isShowChild" class="filter-fav__child-wrapper">
                    <div class="filter-fav__child-container" v-for="(level3, index) in level2.soccerLeagues" :key="index">
                        <div class="filter-fav__card-title">
                            <router-link :to="urlPath('POPULAR', level3)" class="filter-fav__name-wrapper">
                                <Icon
                                    :logoUrl="level3.logo"
                                    :category="'country'"
                                    class="filter-fav__icon"
                                ></Icon>                                
                                <span class="filter-fav__name filter-fav__name-child" :title="level3.name">{{ level3.name }}</span>
                            </router-link>
                            <div class="filter-fav__right">
                                <div class="btn-fav-wrapper" @click="handleFavPopularToggle(level1, level3, $event)">
                                    <div class="btn-fav" :class="favMatchClassObj(level3)" ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalFav  @closeModal="toggleModal" @favModelEmit="listenFavModelEvent" @doneFavToggle="refreshFavAndLeagueList" :tabName="isTab" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ModalFav from "@/views/fav/components/BasketballModal.vue";
export default {
    components: {
        ModalFav,
    },
    data() {
        return {
            leagueList: [],
            isShowModal: false,
            isTab: "FILTER",

            favCategory: []
        }
    },
    watch: {
        isLogin: {
            deep: true,
            handler(newVal, oldVal) {
                this.init();
            },
        },
    },
    mounted() {
       this.init();
    },
    computed: {
        ...mapGetters([
            "isLogin",
            "userInfo",   
            "currentLocale",
            "currentSportType"
        ]),
      
    },
    methods: {
        ...mapActions([
            "getLeagueList",
            "getBasketballLeagueListFav",

            "favLoginTipsChangeEvent",
            "toggleBasketballFavModal",

            "addFavLeague",
            "unFavLeague",

            "toastrFavChangeEvent",

            "getFavLeagueList",
            "getFavTeamList",

            "addFavTeam",
            "addFavLeague",
            "unFavTeam",
            "unFavLeague",

            "favLoginTipsChangeEvent",
        ]),

        init() {
            this.getLeagueListFavData();

            if(this.isLogin) {
                this.getFavCategoryData(true);
            }
        },

        async getLeagueListFavData() {
            const result = await this.getBasketballLeagueListFav();
            this.leagueList = result.result.categories;
        },

        handleFilterChildExpand(o, event) {
            if (!o.rootFlag) {
                event.preventDefault();
                event.stopPropagation();
                
                o.isShowChild = !o.isShowChild;
            } 
        },

        async handleFavPopularToggle(o, level2, event) {
			event.preventDefault();

            if (this.isLogin) {
                level2.isFav = !level2.isFav;

                let params = {
                    token: this.userInfo.token,
                    timeZone: this.$tools.getCurrentTimeZone(),
                    leagueId: level2.leagueId
                };

                let res = !level2.isFav
                ? await this.unFavLeague(params)
                : await this.addFavLeague(params);

                if (res.result.businessCode === 0) {
                    this.toastrEvent(res, !level2.isFav, level2.countryDisplayName ? level2.countryDisplayName : level2.name);
                    this.$emit('favFilterEmit');
                } else {
                    level2.isFav = !level2.isFav;
                }

                //update favourite league list data
                this.getFavCategoryData();

                
            } else {
                this.favLoginTipsChangeEvent({isOpen: true, event: event}) 
            }

        },
        async handleFavCategoryToggle(o, type, event) {
            event.preventDefault();

            if (this.isLogin) {
                o.isFav = !o.isFav;

                let params = {
                    token: this.userInfo.token,
                    timeZone: this.$tools.getCurrentTimeZone(),
                };

                if (type === 'COMPETITIONS') {
                    params.leagueId = o.id;

                    let res = !o.isFav
                        ? await this.unFavLeague(params)
                        : await this.addFavLeague(params);
                    
                    if (res.result.businessCode === 0) {
                        this.$emit('favFilterEmit');
                        this.toastrEvent(res, !o.isFav, o.name);

                        //update popular league list data
                        this.updatePopularLeagueList(o);
                    }
                    
                
                } else if (type === 'TEAMS') {
                    params.teamId = o.id;

                    let res = !o.isFav
                    ? await this.unFavTeam(params)
                    : await this.addFavTeam(params);
                    
                    if (res.result.businessCode === 0) {
                        this.$emit('favFilterEmit');
                        this.toastrEvent(res, !o.isFav, o.name);
                    }
                }

                //update favourite league list data
                this.updateFavCategoryList(o.id);
            } else {
                this.favLoginTipsChangeEvent({isOpen: true, event: event}) 
            }
        },

        handleFavCategoryExpand(o) {
            if (o.list.length > 0) {
                o.isExpand = !o.isExpand;
            }
        },

        favMatchClassObj(o) {
			return {
				'selected': o.isFav
			}
		},

        // toggleGlobalSearchModal(event) {
        //     if (this.isLogin) {
        //         this.toggleFavModal();
        //     } else {
        //         this.favLoginTipsChangeEvent({isOpen: true,triggerOnParent: true, event: event}) 
        //     }  
        // },

        toggleModal() {
           this.toggleBasketballFavModal();
           this.favLoginTipsChangeEvent({isOpen: false});
        },

        async getFavCategoryData(isFirstLoad) {
             let params = {
                token: this.userInfo.token,
                timeZone: this.$tools.getCurrentTimeZone(),
            };

            const resultLeague = await this.getFavLeagueList(params);
            const resultTeam = await this.getFavTeamList(params);

            this.favCategory = [this.reMapCategory(resultLeague, 'COMPETITIONS', 0, isFirstLoad) , this.reMapCategory(resultTeam, 'TEAMS', 1, isFirstLoad)]
        },

        listenFavModelEvent() {
            this.$emit('favFilterEmit')
        },

        reMapCategory(data, title, index, isFirstLoad) {
            let isExpand = false;

            if (this.favCategory[index]) {
                isExpand = this.favCategory[index].isExpand;
            } else {
                if (isFirstLoad) {
                    isExpand = false;
                } else if (data.result.length === 0 ) {
                    isExpand = false;
                }
            }

            return  {
                list: data.result,
                isExpand: isExpand,
                title: title
            }
        },

        urlPath(type, info) {
            if (type === "COMPETITIONS") {
                return {
                    path: `/${this.currentLocale}/${this.currentSportType}/${info.name}/${info.key}`,
                    query: {
                        leagueId: info.id,
                    },
                };
            } else if (type === 'TEAMS') {
                return {
                    path: `/${this.currentLocale}/${this.currentSportType}/team/${info.name.split(" ").join("-")}/${info.id}`,
                };
            } else if (type === "POPULAR") {
                return {
                    path: `/${this.currentLocale}/${this.currentSportType}/${info.countryKey}/${info.leagueKey}`,
                    query: {
                        leagueId: info.leagueId,
                    },
                };
            }
                
        },

        toastrEvent(res, isFav, msg) {
            if (res.result.businessCode === -1) {
            } else {
                let paramsToastr = {
                    isOpen: true,
                    msg: msg,
                    isAddedFav: isFav ? false : true,
                };

                this.toastrFavChangeEvent(paramsToastr);
              
            }
        },

        updateFavCategoryList(id) {
            for (let i = 0; i < this.favCategory.length; i++) {
                let level1 = this.favCategory[i];

                for (let x = 0; x < level1.list.length; x++) {
                    let level2 = level1.list[x];
                    if (level2.id === id) {
                        //remove from list
                        level1.list.splice(x,1)
                    }
                }

                //close the favourite category child if there is no data
                if (level1.list.length === 0) {
                    level1.isExpand = false;
                }
            }
        },

        updatePopularLeagueList(o) {
            for (let i = 0; i < this.leagueList.length; i++) {
                let level1 = this.leagueList[i];

                for (let x = 0; x < level1.countries.length; x++) {
                    let level2 = level1.countries[x];

                    if (level2.leagueId === o.id) {
                        level2.isFav = o.isFav;
                    }

                    for (let y = 0; y < level2.soccerLeagues.length; y++) {
                        if (level2.soccerLeagues[y].leagueId === o.id) {
                            level2.soccerLeagues[y].isFav = o.isFav;
                        }
                    }
                }
            }    
        },

        // refresh fav & league list after favourite operation done on fav modal
        refreshFavAndLeagueList() {
            this.getFavCategoryData();
            this.getLeagueListFavData();
        },
    }
}
</script>

<style scoped>
    .filter-wrapper{
        width: 187px;
    }
    .search-btn__fav{
        background-color: var(--bg-selected);
        border-radius: .5rem;
        font-size: 0.6875rem;
        padding: .5rem;
        text-align: center;
        color: black;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search-placeholder{
        font-size: 0.6875rem;
        margin-left: .25rem;
    }
    .filter-fav__card{
        margin-bottom: 1rem;
    }
    .filter-fav__card-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 1rem;
    }
    .filter-fav__card-text{
        font-size: 0.6875rem;
        font-weight: 700;
    }
    .filter-fav__card-title__wrapper{
        margin-bottom: 1rem;
    }
    .filter-fav__icon{
        margin-right: 0.625rem;
        width: 1.25rem;
        height: 0.875rem;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .filter-fav__name-wrapper{
        display: flex;
        align-items: center;
        font-size: 0.5625rem;
    }
    .filter-fav__title{
        font-size: 0.6875rem;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }
    .filter-fav__right{
        display: flex;
        justify-content: center;
        width: 1.25rem;
        cursor: pointer;
        display: none;
    }
    .filter-fav__child-wrapper{
        padding-left: 1.875rem;
    }
    .filter-fav__child-container{
        margin-bottom: 1rem;
    }
    .filter-fav__name{
        flex: 1;
        padding-right: .25rem;
    }
    .filter-fav__name-child{
        color: #ABBCC4;
        font-size: 0.5625rem;
        font-weight: 400;
    }

    .filter-fav__name:hover,
    .filter-fav__name-child:hover{
        color: var(--color-hover);
    }

    .filter-fav__arrow>svg{
        transition: transform .2s ease;
    }
    .filter-fav__arrow.selected >svg{
        transform: rotate(180deg);
    }
</style>